import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import { PageNotFoundIllustration, BrassLogo } from 'assets/svgs';

import * as styles from './PageNotFound.module.scss';

interface PageNotFoundProps {
	className?: string;
}

const PageNotFound: React.FC<PageNotFoundProps> = ({ className }) => {
	return (
		<section className={cx(styles.PageNotFound, className)}>
			<header className={styles.PageNotFound_header}>
				<Link to="/">
					<BrassLogo />
				</Link>
			</header>
			<div className={styles.PageNotFound_text_box}>
				<h1>The page you are looking for does not exist</h1>
				<p>
					It may have been moved or deleted, or you simply entered a wrong
					address. <Link to="/">Return to login</Link>
				</p>
			</div>
			<PageNotFoundIllustration className={styles.PageNotFound_illustration} />
		</section>
	);
};

export default PageNotFound;
